import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nine from "../components/Nine"
import Brands from "../components/Brands"
import Contact from "../components/Contact"
import SubHero from "../components/SubHero"
import MainImage from "../components/MainImage"
import OrangeCTA from "../components/OrangeCTA"
import { useShopifySupportQuery } from "../hooks/useShopifySupportQuery"
import YearsInBusiness from "../components/YearsInBusiness"

const ShopifySupportPage = () => {
  const data = useShopifySupportQuery()
  const short = data.allSanityShopifySupport.edges[0].node
  return (
    <Layout>
      <SEO
        title="Shopify Support"
      />
      <SubHero
        heroImage={short.heroImage.asset.fluid}
        heroTitle={short.heroTitle}
        heroSubtitle={short.heroSubtitle}
        icon1={short.heroIcon1.asset.fluid.src}
      />
      <MainImage
        mainTitle={short.mainTitle}
        mainText={short._rawMainText}
        mainImg={short.mainImg.asset.fluid.src}
        mainBtn={short.mainBtn}
        slug="/shopify-support/#contact"
      />
      <Nine
        icon1={short.services1Icon.asset.fluid.src}
        icon2={short.services2Icon.asset.fluid.src}
        icon3={short.services3Icon.asset.fluid.src}
        icon4={short.services4Icon.asset.fluid.src}
        icon5={short.services5Icon.asset.fluid.src}
        icon6={short.services6Icon.asset.fluid.src}
        icon7={short.services7Icon.asset.fluid.src}
        icon8={short.services8Icon.asset.fluid.src}
        icon9={short.services9Icon.asset.fluid.src}
        title1={short.services1Title}
        title2={short.services2Title}
        title3={short.services3Title}
        title4={short.services4Title}
        title5={short.services5Title}
        title6={short.services6Title}
        title7={short.services7Title}
        title8={short.services8Title}
        title9={short.services9Title}
      />
      <OrangeCTA
        title={short.orangeCtaTitle}
        text={short._rawOrangeCtaText}
        btn={short.orangeCtaBtn}
        overlayImg={short.orangeCtaOverlayImg.asset.fluid}
        slug="/shopify-support/#contact"
      />
      <Brands />
      <YearsInBusiness />
      <Contact />
    </Layout>
  )
}

export default ShopifySupportPage
